import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import JSONView from "vue-json-component";
import VueExcelXlsx from "vue-excel-xlsx";

import VueCompositionAPI from '@vue/composition-api'


import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'


// common UI elements. others should be added individually in the view

// BootstrapVue Global registration, no need to declare components individually in different views
Vue.use(BootstrapVue)

Vue.use(JSONView)

Vue.use(VueCompositionAPI)

// For exporting data
Vue.use(VueExcelXlsx);


// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false


var queryParams = new URLSearchParams(location.search);
const token = queryParams.get('botk')
const appName = queryParams.get('app')
const role = queryParams.get('r')
const logo = queryParams.get('l')
const lang = queryParams.get('lang')
const mId = queryParams.get('mId')
const mode = queryParams.get('mode')

if (mode) {
    localStorage.setItem('Sanaga-skin', mode)
}
if (lang) {
    localStorage.setItem('tz-cortex-lang', decodeURI(lang))
}
// if (token) {
//     localStorage.setItem('tz-cortex-token', atob(decodeURI(token)))
// }


// if (logo) {
//     localStorage.setItem('tz-cortex-app-logo', decodeURI(logo))
// }

// if (appName) {
//     localStorage.setItem('tz-cortex-app-name', decodeURI(appName))
// }

// if (role) {
//     localStorage.setItem('tz-cortex-org-role', decodeURI(role))
// }
// if (mId) {
//     localStorage.setItem('tz-cortex-merchant-id', decodeURI(role))
// }

router.beforeEach((to, from, next) => {
    const loader = document.getElementById('loader');
    if(loader){
        loader.style.display = 'block';
    }
    
    next();
    // add spinner
})
router.afterEach((to, from) => {
    const loader = document.getElementById('loader');
    if(loader){
        loader.style.display = 'none';
    }
    // clear spinner
},)


new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')